var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "profile-config" } },
    [
      _c(
        "div",
        { staticClass: "flex flex-row-reverse mb-5" },
        [
          _c(
            "vs-button",
            { attrs: { type: "border" }, on: { click: _vm.adicionarPerfil } },
            [
              _c("i", { staticClass: "onpoint-plus icon-font pr-2" }),
              _vm._v("\n      Adicionar perfil de acesso\n    "),
            ]
          ),
        ],
        1
      ),
      _c(
        "vs-collapse",
        { attrs: { accordion: "", type: "margin" } },
        _vm._l(_vm.perfilList, function (perfil, index) {
          return _c(
            "vs-collapse-item",
            { key: index, attrs: { open: index == 0 } },
            [
              _c("template", { slot: "header" }, [
                _vm._v("\n        " + _vm._s(perfil.Name) + "\n      "),
              ]),
              _c(
                "vs-row",
                { staticClass: "pt-0" },
                [
                  _c(
                    "vs-col",
                    { staticClass: "w-full" },
                    [
                      _vm._l(perfil.Menus, function (menu, indexMenu) {
                        return _c(
                          "div",
                          {
                            key: `menu-${indexMenu}`,
                            staticClass: "mb-8",
                            attrs: { "vs-type": "flex" },
                          },
                          [
                            _c("h6", { staticClass: "text-primary mb-3" }, [
                              _vm._v("Permissões - " + _vm._s(menu.Name)),
                            ]),
                            _vm._l(
                              menu.MenuFuncionalities,
                              function (functionality, indexFunctionality) {
                                return _c(
                                  "div",
                                  {
                                    key: indexFunctionality,
                                    staticClass: "container-functionality",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "onpoint-check-square",
                                    }),
                                    _c("p", [
                                      _vm._v(_vm._s(functionality.Name)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { type: "flat" },
                              on: {
                                click: function ($event) {
                                  return _vm.editarPerfil(perfil)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "onpoint-gear icon-font",
                                staticStyle: {
                                  position: "relative",
                                  top: "2px",
                                },
                              }),
                              _vm._v(
                                "\n              Configurar perfil " +
                                  _vm._s(perfil.Name) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      !perfil.IsDefault
                        ? _c(
                            "div",
                            { staticClass: "flex flex-row-reverse mb-5" },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: { color: "danger", type: "flat" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showConfirmationModal = true
                                      _vm.profileUniqueId = perfil.UniqueId
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "onpoint-trash icon-font pr-2",
                                  }),
                                  _vm._v(
                                    "\n              Excluir perfil " +
                                      _vm._s(perfil.Name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            2
          )
        }),
        1
      ),
      _c(
        "app-dialog",
        {
          attrs: { title: "" },
          on: {
            cancel: function ($event) {
              _vm.showConfirmationModal = false
            },
            confirm: function ($event) {
              return _vm.excluirPerfil()
            },
          },
          model: {
            value: _vm.showConfirmationModal,
            callback: function ($$v) {
              _vm.showConfirmationModal = $$v
            },
            expression: "showConfirmationModal",
          },
        },
        [
          _c("template", { slot: "content" }, [
            _c("h4", { staticClass: "text-center" }, [
              _vm._v(
                "\n        Deseja realmente remover esse o esse perfil?\n      "
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("cadastrar-perfil-component", {
        attrs: {
          showPopup: _vm.showPerfilPopup,
          mode: _vm.mode,
          value: _vm.perfilItem,
          allowedReset: true,
          userTypeId: _vm.perfilHelpers.userTypeEnum.BROKER,
        },
        on: {
          setShowPopup: function ($event) {
            _vm.showPerfilPopup = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }